/* eslint-disable import/no-named-default */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable radix */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-eval */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import type {
  Answer,
  Order,
  Property,
  Question,
} from '../model/model.js';
import {
  AnswerPropertyType,
  ChecklistPropertyType,
  OrderPropertyType,
  QuestionPropertyType,
  QuestionType,
} from '../model/model.js';

import type {
  default as ChecklistUtils,
} from './checklistutils.js';
import {
  QuestionHTML,
} from './questionHTML.js';
import {
  QuestionHTMLutils,
} from '../checklist/questionHTML.js';
import { logEntry } from '../Utils/logger.js';
import { getData } from '../Utils/storage.js';

let _questions: Question[];
let _utils: ChecklistUtils;
let _HTMLutils: QuestionHTMLutils;
let _holdRender = false;

export class Renderer {
  _excludedInstance: number[] = [];

  constructor() {
    _questions = [];
    _utils = window.utils;
    _HTMLutils = new QuestionHTMLutils();
  }

  private checkIDs(order: Order) {
    if (order.id == 0) {
      order.id = _utils.getNextAvailableTemporaryID(order);

      if (order.item) {
        for (let i = 0; i < order.item.length; i++) {
          const item = order.item[i];
          item.parent_id = order.id;
          item.id = _utils.getNextAvailableTemporaryID(item);
        }
        for (let i = 0; i < order.item.length; i++) {
          const item = order.item[i];
          if (item.product) {
            for (let j = 0; j < item.product.length; j++) {
              const product = item.product[j];
              if (product.id == 0) {
                product.id = _utils.getNextAvailableTemporaryID(product);
              }
              if (product.checklist) {
                for (let k = 0; k < product.checklist.length; k++) {
                  const checklist = product.checklist[k];
                  if (checklist.id === 0) {
                    checklist.id = _utils.getNextAvailableTemporaryID(checklist);
                    checklist.parent_id = order.id;
                    for (let l = 0; l < checklist.properties.length; l++) {
                      const prop = checklist.properties[l];
                      if (prop.id === 0) {
                        prop.parent_id = checklist.id;
                      }
                    }
                    for (let m = 0; m < checklist.question.length; m++) {
                      const question = checklist.question[m];
                      if (question.id === 0) {
                        question.id = _utils.getNextAvailableTemporaryID(question);
                        question.parent_id = checklist.id;
                        question._checklistid = checklist.id;
                        question._order_id = order.id;
                        for (let n = 0; n < question.properties.length; n++) {
                          const prop = question.properties[n];
                          if (prop.id === 0) {
                            prop.parent_id = question.id;
                          }
                        }
                        if (question.answer && question.answer.length > 0) {
                          for (let o = 0; o < question.answer.length; o++) {
                            const answer = question.answer[o];
                            if (answer.id === 0) {
                              answer.id = _utils.getNextAvailableTemporaryID(answer);
                              answer.parent_id = question.id;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  async redraw(order: Order) {
    _holdRender = false;
    // Check that all objects have IDs and set them to temporary IDs if not
    this.checkIDs(order);
    _utils.updateSeqNo(order);
    // Put all questions from all checklists into a new array
    _questions = [];

    this._excludedInstance = [];

    let preQuestion: Question = {} as Question;
    if (order.item) {
      for (let i = 0; i < order.item.length; i++) {
        const item = order.item[i];
        if (!item._delete) {
          /*
          // insert questiontype Groupheading, but not on item 0 and 1
          // and there are questions in the checklists int the products for the item
          let noQuestions = 0;
          if (item.product) {
            for (let j = 0; j < item.product.length; j++) {
              const product = item.product[j];
              if (product.checklist) {
                for (let k = 0; k < product.checklist.length; k++) {
                  const checklist = product.checklist[k];
                  if (checklist.question) {
                    noQuestions += checklist.question.length;
                  }
                }
              }
            }
          }
          if (itemNo > 1 && noQuestions > 0) {
            const itemtext = (window.activelanguage === 'NOR' ? 'Produkt' : 'Product');
            let existsItem;
            try {
              existsItem = item.product[0].checklist[0].question.find((q) => q.properties.find((j) => j.type === QuestionPropertyType.Type)?.value === QuestionType.Groupheading);
            } catch (e) {
              logEntry(`${JSON.stringify(e)} ${e}`);
            }
            let headerQuestion: Question;
            // if (item.product && item.product[0] && item.product[0].checklist && item.product[0].checklist[0] && item.product[0].checklist[0].id && item.product[0].checklist[0].question.length) {
            // Add checklist if not exists
            if (item.product && item.product[0] && !item.product[0].checklist.length) {
              item.product[0].checklist = [];
              item.product[0].checklist.push({
                id: 99999999,
                parent_id: item.product[0].id,
                properties: [],
                question: [],
              });
            }

            if (item.product && item.product[0]) {
              headerQuestion = {
                id: 1,
                parent_id: item.product[0]?.checklist[0]?.id,
                answer: new CustomArray<Answer>(),
                properties: [
                  {
                    id: 1,
                    parent_id: 1,
                    type: QuestionPropertyType.Type,
                    value: QuestionType.Groupheading,
                  },
                  // Category
                  {
                    id: 1,
                    parent_id: 1,
                    type: QuestionPropertyType.Category,
                    value: '3000',
                  },
                  // SequenceNumberq
                  {
                    id: 1,
                    parent_id: 1,
                    type: QuestionPropertyType.SequenceNumber,
                    value: '1',
                  },
                  // Text
                  {
                    id: 1,
                    parent_id: 1,
                    type: QuestionPropertyType.NOR,
                    value: `<center><b>${itemtext} ${itemNo - 1}</b></center>`,
                  },
                ],
                _checklistid: 99999999,
                _order_id: order.id,
                _product: item.product[0].id,
                _itemid: item.id,
                _seqno: 1,
              };

              // add to item.product[0].checklist[0].question
              // if (product == 'Salg_Montering' || product == 'Salg_Luft_Vann' || product == 'Salg_Luft_Luft_Multi' || product == 'Salg_Luft_Luft') {
              if (!existsItem) {
                // item.product[0].checklist[0].question.unshift(headerQuestion);

                // insert questiontype Groupheading if not already exists in _questions
                const existsQuestion = _questions.find((q) => (q.properties.find((j) => (j.type == QuestionPropertyType.Type))?.value == QuestionType.Groupheading) && (q._itemid == item.id));
                if (!existsQuestion) {
                  _questions.push(headerQuestion);
                }
              }
              // }
            }
          }
          // end insert questiontype Groupheading
          */
          if (item.product) {
            for (let j = 0; j < item.product.length; j++) {
              const product = item.product[j];
              if (product.checklist) {
                for (let k = 0; k < product.checklist.length; k++) {
                  const checklist = product.checklist[k];
                  const ChecklistPrerender = checklist.properties.find((p) => p.type == ChecklistPropertyType.PreRender)?.value.toString();
                  if (ChecklistPrerender && !eval(ChecklistPrerender)) {
                    break;
                  } else if (checklist.question) {
                    for (let l = 0; l < checklist.question.length; l++) {
                      const q = checklist.question[l];
                      q._itemid = item.id;
                      q._product = product.id;
                      q._order_id = order.id;
                      if (JSON.stringify(q) !== JSON.stringify(preQuestion) && q._seqno !== 0) {
                        _questions.push(q);
                        preQuestion = q;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const orderName = order.properties.find((p) => p.type == OrderPropertyType.Name)?.value.toString() || '';
    if (
      !orderName.match(/^Menu/)
      && !orderName.match(/^SubMenu/)
    ) {
      const salesorderName = _utils.getAnswer('salesreference');
      const salesorderID = window.Data.order.find((i) => i.properties.find((j) => j.type == 1)?.value == salesorderName)?.id;
      const salesorder = window.Data.order.find((i) => i.id == salesorderID);

      // Copy questions from salesorder to order
      if (salesorder && salesorder.item) {
        for (let i = 0; i < salesorder.item.length; i++) {
          const item = salesorder.item[i];
          if (item.product) {
            for (let j = 0; j < item.product.length; j++) {
              const product = item.product[j];
              if (product.checklist) {
                for (let k = 0; k < product.checklist.length; k++) {
                  const checklist = product.checklist[k];
                  if (checklist.question) {
                    for (let l = 0; l < checklist.question.length; l++) {
                      const q = checklist.question[l];
                      if (q.properties.find((p) => p.type == QuestionPropertyType.SpecialHandling)?.value == 'Copy to order') {
                        const readonly = q.properties.find((p) => p.type == QuestionPropertyType.WriteProtected);
                        if (readonly) {
                          readonly.value = 'true;';
                        } else {
                          q.properties.push({
                            id: 0,
                            parent_id: q.id,
                            type: QuestionPropertyType.WriteProtected,
                            value: 'true;',
                          });
                        }
                        _questions.push(q);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // Sort by Category,ChecklistID,Instance,SequenceNo
    _questions.sort(_utils.sortQuestions);

    window.previousQuestionsAnswered = true;
    let htmltext = '';
    // Create HTML text from questions
    for (const i of _questions) {
      htmltext += (await this.evaluate(i)) ?? '';
    }
    return htmltext;
  }

  private async evaluate(question: Question): Promise<string | undefined> {
    const questionType = Number(question.properties.find((p) => p.type === QuestionPropertyType.Type)?.value ?? '');
    if (window.showOnlyRequired && _utils.questionIsAnsweredOrNotRequired(question) && questionType !== QuestionType.Groupheading && questionType !== QuestionType.Orderheading) {
      return;
    }

    if (window.showOnlyRemaining && _utils.questionIsAnswered(question) && questionType !== QuestionType.Groupheading && questionType !== QuestionType.Orderheading) {
      return;
    }

    // hide group?
    if (_holdRender) {
      return;
    }
    const qType = parseInt(
      (question.properties.find((i) => i.type == QuestionPropertyType.Type)
        ?.value as string) || '',
    );
    if (
      qType == QuestionType.Groupheading
      && _HTMLutils.questionAnswer(question) == '1'
    ) {
      this._excludedInstance.push(question._itemid || -1);
    }

    const data = question.properties.filter((i) => i.type == QuestionPropertyType.Data) || null;
    const checklistdata = data && data.length ? data : ([] as Property[]);
    // Questiontype Dropdown?
    if (qType == QuestionType.Dropdown) {
      // Sort checklistdata by NOR, with 'Ingen' first. Sort as numberr if possible
      checklistdata.sort((a, b) => {
        // If value is JSON, parse it and set value to NOR
        let valueA = '';
        let valueB = '';
        try {
          if (a.value.toString().match(/^\{/)) {
            valueA = JSON.parse(a.value.toString()).Item.NOR;
          }
          if (b.value.toString().match(/^\{/)) {
            valueB = JSON.parse(b.value.toString()).Item.NOR;
          }
        } catch (e) {
          logEntry(`${JSON.stringify(e)} ${e}`);
        }

        if (valueA == 'Ingen' || valueA === 'Ingen ekstradeler / reservedeler brukt') {
          return -1;
        }
        if (valueB == 'Ingen' || valueB === 'Ingen ekstradeler / reservedeler brukt') {
          return 1;
        }
        if (!Number.isNaN(Number(valueA)) && !Number.isNaN(Number(valueB))) {
          return Number(valueA) - Number(valueB);
        }
        return valueA.toString().localeCompare(valueB.toString());
      });
      // Replace checklistdata with sorted checklistdata
      question.properties = question.properties.filter((i) => i.type != QuestionPropertyType.Data);
      question.properties.push(...checklistdata);
    }

    // PreRender
    try {
      const value: string = question.properties.find((i) => i.type == QuestionPropertyType.PreRender)
        ?.value.toString() || '';
      if (value > '') {
        // Gets the bearer token from the indexed db for use in the eval...
        // TODO This is not the best way to do it, but it works for now..
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const bearer = await getData('bearer');
        eval(value as string);
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`PreRender: QuestionID ${question.id} ${(e as Error).message}`);
    }

    // Relevant
    try {
      const value: string = question.properties.find((i) => i.type == QuestionPropertyType.Relevant)
        ?.value.toString() || '';
      if (value > '') {
        const result = new Function(value)();
        if (!result) {
          return;
        }
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`Relevant: QuestionID ${question.id}${(e as Error).message}`);
    }

    let newHTML = '';

    if (question.properties.find((i) => i.type == QuestionPropertyType.SpecialHandling)?.value == 'ShowAllAnswers') {
      for (let i = question.answer?.length || -1; i > -1; i--) {
        newHTML += this.renderQuestion(question, i);
      }
      console.log('ShowAllAnswers');
    } else {
      newHTML = this.renderQuestion(question);
    }

    if (newHTML.match('data-answered="no"')) {
      window.previousQuestionsAnswered = false;
    }

    const instance: number = _utils.instance(question);
    const type = parseInt(
      (question.properties.find((i) => i.type == QuestionPropertyType.Type)
        ?.value as string) || '0',
    );

    if (this._excludedInstance.includes(instance) && type != QuestionType.Groupheading) {
      return;
    }

    // if (newHTML.toString().length) {
    //   _HTMLtext += newHTML;
    // }

    // PostRender
    try {
      const value: string = question.properties.find((i) => i.type == QuestionPropertyType.PostRender)
        ?.value.toString() || '';
      if (value > '') {
        eval(value as string);
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`PreRender: QuestionID ${question.id}${(e as Error).message}`);
    }

    return newHTML;
  }

  private customData(question: Question, answerNo?: number) {
    const _res: Answer[] = question.answer?.sort(_utils.sortAnswers) || [];
    if (!_res || _res.length == 0) {
      return undefined;
    }
    if (!answerNo) {
      answerNo = 0;
    } else {
      answerNo = Math.min(answerNo, _res.length - 1);
    }
    const _answer = _res[answerNo].properties.find(
      (i) => i.type == AnswerPropertyType.CustomQuestionProperties,
    );
    if (_answer) {
      let data;
      try {
        data = JSON.parse(_answer.value as string);
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        console.log(`customData error: ${question.id}${(e as Error).message}`);
        data = undefined;
      }
      return data;
    }
    return undefined;
  }

  private renderQuestion(question: Question, answerNo?: number) {
    const questionHTML = new QuestionHTML();

    if (question.id == 0) {
      console.log(`PreRender: QuestionID ${question}`);
    }

    const customdata = this.customData(question, answerNo);
    if (customdata) {
      question.properties = customdata;
    }

    const instance: number = _utils.instance(question);
    const type = parseInt(
      (question.properties.find((i) => i.type == QuestionPropertyType.Type)
        ?.value as string) || '0',
    );

    const ID = `data-type="${question.properties.find(
      (i) => i.type == QuestionPropertyType.Type,
    )?.value || ''
    }" `
      + `data-checklist="${question._checklist
      }" `
      + `data-orderid="${question._order_id
      }" `
      + `data-productid="${question._product
      }" `
      + `data-checklistid="${question._checklistid
      }" `
      + `data-questionid="${question.id
      }" `
      + `data-category="${question.properties.find((i) => i.type == QuestionPropertyType.Category)
        ?.value || ''
      }" `
      + `data-itemid="${instance
      }" `
      + `data-seqno="${question.properties.find(
        (i) => i.type == QuestionPropertyType.SequenceNumber,
      )?.value || ''
      }" `
      + `data-QuestionID="${question.id
      }" `
      + 'id="'
      + `qId-${question.id}`
      + '" '
      + `data-id="${question.id
      }-${question.properties.find((i) => i.type == QuestionPropertyType.Category)
        ?.value || ''
      }-${instance
      }-${question.properties.find(
        (i) => i.type == QuestionPropertyType.SequenceNumber,
      )?.value || ''
      }-`
      + '" ';

    switch (type) {
      case QuestionType.Text:
        return questionHTML.text(ID, question, answerNo);
      case QuestionType.Check:
        return questionHTML.yesno(ID, question, answerNo);
      // return questionHTML.check(ID, question, answerNo);
      case QuestionType.Dropdown:
        return questionHTML.dropdown(ID, question, answerNo);
      case QuestionType.Info:
        return questionHTML.information(ID, question, answerNo);
      case QuestionType.Header:
        return questionHTML.header(ID, question, answerNo);
      case QuestionType.File:
        return questionHTML.file(ID, question, answerNo);
      case QuestionType.HTMLlink:
        return questionHTML.htmllink(ID, question, answerNo);
      case QuestionType.HTMLlinks:
        return questionHTML.htmllinks(ID, question, answerNo);
      case QuestionType.Date:
        return questionHTML.date(ID, question, answerNo);
      case QuestionType.DateTime:
        return questionHTML.datetime(ID, question, answerNo);
      case QuestionType.InstallPicture:
        return questionHTML.installpicture(ID, question, answerNo);
      case QuestionType.MultipleInstallPicture:
        return questionHTML.multipleinstallpicture(ID, question, answerNo);
      case QuestionType.Email:
        return questionHTML.email(ID, question, answerNo);
      case QuestionType.Signature:
        return questionHTML.signature(ID, question, answerNo);
      case QuestionType.Note:
        return questionHTML.note(ID, question, answerNo);
      case QuestionType.Picture:
        return questionHTML.picture(ID, question, answerNo);
      case QuestionType.MultiplePicture:
        return questionHTML.multiplepicture(ID, question, answerNo);
      case QuestionType.Button:
        return questionHTML.button(ID, question, answerNo);
      case QuestionType.Buttons:
        return questionHTML.buttons(ID, question, answerNo);
      case QuestionType.YesNo:
        return questionHTML.yesno(ID, question, answerNo);
      case QuestionType.Barcodescan:
        return questionHTML.barcodescan(ID, question, answerNo);
      case QuestionType.Groupheading:
        return questionHTML.groupheading(ID, question, answerNo);
      case QuestionType.Orderheading:
        return questionHTML.orderheading(ID, question, answerNo);
      case QuestionType.YesNoMaybe:
        return questionHTML.yesnomaybe(ID, question, answerNo);
      case QuestionType.FSM:
        return questionHTML.fsm(ID, question, answerNo);
      case QuestionType.TableView:
        return questionHTML.tableview(ID, question, answerNo);
      case QuestionType.LiveTable:
        return questionHTML.livetable(ID, question, answerNo);
      case QuestionType.Approve:
        return questionHTML.approve(ID, question, answerNo);
      case QuestionType.HTMLnote:
        return questionHTML.htmlnote(ID, question, answerNo);
      case QuestionType.ProductLine:
        return questionHTML.productline(ID, question, answerNo);
      case QuestionType.Number:
        return questionHTML.number(ID, question, answerNo);
      default:
        try {
          const HTML = `<div class="question">${question.id
          } <label>Unknown inputtype: ${type
          }</label></div>`;
          return HTML;
        } catch (e) {
          logEntry(`${JSON.stringify(e)} ${e}`);
          const HTML = '<div class="question">Error during parsing of question'
            + ' <label>Error during parsing of question</label></div>';
          return HTML;
        }
    }
  }
}

export default Renderer;
